// Components
import { LayoutWrapper } from "components/common/LayoutWrapper";
import { IntroRetailer } from "components/common/IntroRetailer";
import { IntroFeedback } from "components/common/IntroFeedback";
import { IntroAbout } from "components/common/IntroAbout";
import { IntroInTouch } from "components/common/IntroInTouch";
import { IntroProfitability } from "src/components/common/IntroProfitability";
import { IntroSimplicity } from "src/components/common/IntroSimplicity";
import { IntroFreedom } from "components/common/IntroFreedom";
import { IntroVideo } from "components/common/IntroVideo";
import { IntroProximity } from "components/common/IntroProximity";
import { IntroHero } from "components/common/IntroHero";
import { SigninRequestBlock } from "components/common/SigninRequestBlock";

// Definitions
import type { GetServerSidePropsContext } from "next";
import type { NextPageCustom } from "models/Next";
import type { GSSPParams } from "models/Base";

// Utils
import { setupPageConfigSSR } from "utils/page-ssr-config";
import { logger } from "utils/logger";
import { verifyEnvironment } from "utils/verify-environment";
import { AuthUserDraftProvider } from "contexts/auth-user-draft";
import { IntroLead } from "components/common/IntroLead";

export const getServerSideProps = async (ctx: GetServerSidePropsContext<GSSPParams>) => {
  const { isDevelopment } = verifyEnvironment();
  const localeGroups = ["common", "intro", "inputs", "buttons", "signin", "cookies", "settings"];

  try {
    const pageParams = {
      ctx,
      localeGroups,
    };

    const { props: pageProps, redirect: pageRedirect } = await setupPageConfigSSR(pageParams);

    return {
      props: {
        ...pageProps,
      },
      ...(pageRedirect && {
        redirect: {
          ...pageRedirect,
        },
      }),
    };
  } catch (error) {
    const err = error as Error;
    if (isDevelopment) {
      logger.serverDevelopmentLogger.error(
        `GetServerSideProps {"page": "/signin", producer: "SSR Server" } has crushed with message: ${err.message}`,
      );
    }
    return {
      props: {},
    };
  }
};

const SigninPage: NextPageCustom = () => {
  return (
    <>
      <IntroHero form={<SigninRequestBlock testId="signin-form" />} />
      <IntroProfitability />
      <IntroSimplicity />
      <IntroProximity />
      <IntroLead type="signin" />
      <IntroRetailer />
      <IntroFreedom />
      <IntroVideo />
      <IntroFeedback />
      <IntroLead type="feedback" />
      <IntroAbout />
      <IntroInTouch />
    </>
  );
};

SigninPage.getLayout = (page) => {
  return (
    <LayoutWrapper hasIntro navigationType="signup" typeHeader="ghost" hasCountrySelector>
      <AuthUserDraftProvider>{page}</AuthUserDraftProvider>
    </LayoutWrapper>
  );
};

export default SigninPage;
